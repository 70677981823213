// Get and Store all Locations in localStorage  
export const fetchLocations = async (locationUrl, config, onUpdate) => {
    const cachedLocationData = localStorage.getItem('locations');

    // Get Locations from the cached data - set it to an empty array if it does not exist
    let cachedLocations = cachedLocationData ? JSON.parse(cachedLocationData).locations : [];

    // Ensure Locations is always an array
    let locations = Array.isArray(cachedLocations) ? cachedLocations : [];
    let page = 1;

    // Loop through the pages of locations until there are no more pages
    while (true) {
        const response = await fetch(`${locationUrl}?&page=${page}`, config);

        if (!response.ok) {
            console.error('Error fetching locations:', response);
            return;
        }

        const data = await response.json();

        if (Array.isArray(data.locations) && data.locations.length > 0) {
            // Check for updates and add new records to the locations array
            data.locations.forEach(newLocation => {
                const existingIndex = locations.findIndex(existing => existing.id === newLocation.id);

                if (existingIndex > -1) {
                    // If the location already exists, update the location
                    locations[existingIndex] = {
                        ...locations[existingIndex],
                        name: newLocation.name,
                    };
                } else {
                    // If the location does not exist, add the location
                    locations.push({
                        id: newLocation.id,
                        name: newLocation.name,
                    });
                }
            });

            // Invoke the callback function with the updated locations array
            if (onUpdate) {
                onUpdate(locations);
            }

            page++;
        } else {
            break;
        }
    }

    // Convert the array of locations to an object where the keys are the location_ids and the values are the location names
    const locationMap = locations.reduce((map, location) => {
        map[location.id] = {
            id: location.id,
            name: location.name,
        };
        return map;
    }, {});

    // Store the locations in localStorage cache called 'locations'
    localStorage.setItem('locations', JSON.stringify({ locations: locationMap }));

    return locationMap;
};

// Fetch all active requesters including agents in the department
export const fetchRequesters = async (requesterUrl, config, onUpdate) => {
    const cachedRequesterData = localStorage.getItem('requesters');
    let cachedRequesters = cachedRequesterData && cachedRequesterData !== '' ? JSON.parse(cachedRequesterData).requesters : [];    // Check if cachedData is not an empty string before trying to parse it
    let requesters = Array.isArray(cachedRequesters) ? cachedRequesters : [];   // Ensure requesters is always an array
    let  page = 1 


    // Loop through the pages of requesters until there are no more pages
    while (true) {
        const response = await fetch(`${requesterUrl}&page=${page}`, config);

        if(!response.ok) {
            console.error('Error fetching requesters:', response);
            return;
        }

        const data = await response.json();


        if (Array.isArray(data.requesters) && data.requesters.length > 0) {
            
            // Check for updates and add new records to the requesters array
            data.requesters.forEach(newRequester => {
                const existingIndex = requesters.findIndex(existing => existing.id === newRequester.id);
            
                if (existingIndex > -1) {

                    // If the requester already exists, update the requester
                    requesters[existingIndex] = {
                        ...requesters[existingIndex],
                        name: newRequester.first_name + ' ' + newRequester.last_name,
                        location: newRequester.location_name,
                        timezone: newRequester.time_zone,
                        asset_access: newRequester.asset_access_level,
                        ticket_access: newRequester.can_see_all_tickets_from_associated_departments,
                        email: newRequester.primary_email,
                        active: newRequester.active,
                        created_at: newRequester.created_at,

                    };
                } else {

                    // If the requester does not exist, add the requester
                    requesters.push({
                        id: newRequester.id,
                        name: newRequester.first_name + ' ' + newRequester.last_name,
                        location: newRequester.location_name,
                        timezone: newRequester.time_zone,
                        asset_access: newRequester.asset_access_level,
                        ticket_access: newRequester.can_see_all_tickets_from_associated_departments,
                        email: newRequester.primary_email,
                        active: newRequester.active,
                        created_at: newRequester.created_at,
                    });
                }
            });

            // Invoke the callback function withy the updated requesters array
            if (onUpdate)   {
                onUpdate(requesters);
            }

            page++;
        } else {
            break;
        }
    }

    // Convert the array of requesters to an object where the keys are the user_ids and the values are the user names
    const requesterMap = requesters.reduce((map, requester) => {
        map[requester.id] = {
            id: requester.id,
            name: requester.name,
            location: requester.location,
            timezone: requester.timezone,
            asset_access: requester.asset_access,
            ticket_access: requester.ticket_access,
            email: requester.email,
            active: requester.active,
            created_at: requester.created_at,
        };
        return map;
    }, {});


    // Store the requesters in localStorage cache called 'requesters'
    localStorage.setItem('requesters', JSON.stringify({ requesters: requesterMap }));
    

    return requesters;
};



// Define the fields to keep in the array 
let includedFields = ['id', 'user_id', 'asset_type_id','location_id', 'location_name', 'product', 'asset_tag', 'name', 'serial_number', 'user_name', 'asset_type', 'asset_state', 'disk', 'processor', 'memory', 'product_name', 'warranty_expiry_date', 'acquisition_date','is_o84_loan_device', 'expected_loan_return_date', 'assigned_on', 'asset_type_name'];


// Check for if assets are stored in Storage. If not, fetch them from the API 
export const fetchAndEnrichAssets = async (assetUrl, config) => { 

    const cachedData = localStorage.getItem('assets');

    let lastUpdate = cachedData ? JSON.parse(cachedData).timestamp : new Date('1970-01-01').getTime();      // Get timestamp from the cached data - set it to 1970 if it does not exist
    let lastUpdateUTC = new Date(lastUpdate).toISOString().split('T')[0];                                   // Convert timestamp to UTC

   
    let cachedAssets = cachedData ? JSON.parse(cachedData).assets : [];                 // Get assets from the cached data - set it to an empty array if it does not exist
    let assets = cachedAssets || [];                                                    //Ensure assets is always an array

   
    let assetTypeCache = {}, userCache = {}, productCache = {}, locationCache = {};     // Create empty arrays to store additional asset data
    let page = 1;                                                                       // Set the page number to 1

    // Loop through the pages of assets until there are no more pages
    while (true) {
        const response = await fetch(`${assetUrl}%20AND%20updated_at:>'${lastUpdateUTC}'"&page=${page}`, config);
        const data = await response.json();
        
        // If there are assets on the page, add them to the assets array and increment the page number
        if (Array.isArray(data.assets) && data.assets.length > 0) {
            assets = [...assets, ...data.assets];
            page++;
        } else {
            break; // If there are no assets on the page, break out of the loop
        }
    }
    

    // Get additional data for each asset: asset_type_name, user_name, product_name, location_name
    for (let asset of assets) {

        // Get the name of each asset type
        if (asset.asset_type_id && !assetTypeCache[asset.asset_type_id]) {
            const assetTypeResponse = await fetch(`https://origin84helpdesk.freshservice.com/api/v2/asset_types/${asset.asset_type_id}`, config);
            const typeData = await assetTypeResponse.json();
            assetTypeCache[asset.asset_type_id] = typeData.asset_type.name;
        }
        asset.asset_type_name = assetTypeCache[asset.asset_type_id];
    
        // Move data from asset.type_fields to the top level
        if (asset.type_fields) {
            Object.assign(asset, asset.type_fields);
            delete asset.type_fields;
        }

        // Get the userCache from the fetchRequesters function
        const cachedRequesters = localStorage.getItem('requesters');
        if (cachedRequesters) {
            userCache = JSON.parse(cachedRequesters).requesters;
        }
        asset.user_name = userCache[asset.user_id] ? userCache[asset.user_id].name : '';


        // Get the productCache from the fetchProducts function
        const cachedProducts = localStorage.getItem('products');
        if (cachedProducts) {
            productCache = JSON.parse(cachedProducts).products;
            }
        
        asset.product_name = productCache[asset.product] ? productCache[asset.product].name : '';
 

        // Get the locationCache from the fetchLocations function
        const cachedLocations = localStorage.getItem('locations');
        if(cachedLocations) {
            locationCache = JSON.parse(cachedLocations).locations;
        }
        asset.location_name = locationCache[asset.location_id] ? locationCache[asset.location_id].name : '';   
   
        
        // Iterate over the keys of the asset object
        for (let key in asset) {

            // Check if the key ends with an underscore followed by a number
            if (/_\d+$/.test(key)) {
                // Create a new key that removes the underscore and the number
                let newKey = key.replace(/_\d+$/, '');
                // Assign the value from the old key to the new key
                asset[newKey] = asset[key];
                // Delete the old key
                delete asset[key];
            } 
        } 
    }

        // Replace the assets array with a new array that only contains the assets with the fields listed in includedFields
        assets = assets.map(asset => {
            let selectedFields = {};
            for (let key in asset) {
                if (includedFields.includes(key)) {
                    selectedFields[key] = asset[key];
                }
            }
            return selectedFields;
        });
  

        // Create a new array to update the cached assets with the new data
        const updatedAssets = cachedAssets.map(asset => {
            const updatedRecord = assets.find(record => record.id === asset.id);
            return updatedRecord ? updatedRecord : asset;
        });

        // Create a new array to add new assets to the cached assets
        const newRecords = assets.filter(asset => !cachedAssets.some(cachedAsset => cachedAsset.id === asset.id));

        // Combine the updated assets and new assets into a single array 
        const finalAssets = [...updatedAssets, ...newRecords];

        // Store the assets in localStorage cache called 'assets' with the current timestamp
        localStorage.setItem('assets', JSON.stringify({ assets: finalAssets, timestamp: Date.now() }));

        // Return the finalAssets array
        return finalAssets;
};

import React, { useRef, forwardRef, useImperativeHandle, useState } from "react";
import ReactDOM from "react-dom";
import { FwButton, FwModal, FwModalTitle, FwModalContent, FwModalFooter, FwTabs, FwCard } from "@freshworks/crayons/react";
import '@freshworks/crayons/css/crayons-min.css';
import { formatDate } from './helpers.js';
import EditAsset from './editAsset.js';

const AssetDetailModal = forwardRef(({ assets }, ref) => {
  const modal = useRef(null);

  const [asset, setAsset] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const [showEditForm, setShowEditForm] = useState(false); // Add this line

  useImperativeHandle(ref, () => ({
    open: (asset_id) => {
      let foundAsset;
      if (Array.isArray(assets)) {
        foundAsset = assets.find(asset => String(asset.id) === String(asset_id));
      }
      
      setAsset(foundAsset);
      modal.current.open();
    }
  }));


  return (
    <div>
        <FwModal id='asset-detail-slider' slider ref={modal} size='large'>
          <FwModalTitle>
            <span className="fw-type-h3">Asset Details</span>
            <FwButton onClick={() => {
              setShowEditForm(!showEditForm)
              setSuccessMessage(null);
            }} style={{marginLeft: 'auto'}}>Edit</FwButton>
          </FwModalTitle>
          <FwModalContent> 
            <div>
              <div>
              {showEditForm && 
                <div className='fw-card-1'>
                  <EditAsset asset={asset} setSuccessMessage={setSuccessMessage}/>
                  <br />
                </div>
              }
              <br />
              </div>
            </div>
            <div>
              <div className="fw-card-2">
                <span className="fw-type-h4">General</span>
                <div style={{borderTop: '1px solid #CFD7DF'}}></div>
                  <br />
                <table id="modalGeneralDetails">
                  <tbody>
                    <tr>
                      <td className="fw-type-h5">Name: </td>
                      <td className="fw-type-m">{asset && asset.name}</td>
                    </tr>
                    <tr>
                      <td className="fw-type-h5">Asset Tag: </td>
                      <td className="fw-type-m">{asset && asset.asset_tag}</td>
                    </tr>
                    <tr>
                      <td className="fw-type-h5">Asset Type: </td>
                      <td className="fw-type-m">{asset && asset.asset_type_name}</td>
                    </tr>
                    <tr>
                      <td className="fw-type-h5">Location: </td>
                      <td className="fw-type-m">{asset && asset.location_name}</td>
                    </tr>
                    <tr>
                      <td className="fw-type-h5">Asset State: </td>
                      <td className="fw-type-m">{asset && asset.asset_state}</td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <span className="fw-type-h4">Assignment</span>
                <div style={{borderTop: '1px solid #CFD7DF'}}></div>
                  <br />
                <table id="modalAssignmentDetails">
                  <tbody>
                    <tr>
                      <td className="fw-type-h5">Assigned To: </td>
                      <td className="fw-type-m">{asset && asset.user_name}</td>
                    </tr>
                    <tr>
                      <td className="fw-type-h5">Assigned On: </td>
                      <td className="fw-type-m">{formatDate(asset && asset.assigned_on)}</td>
                    </tr>
                  </tbody>
                </table>
                <br />
              </div>
                  

              {asset && asset.asset_type_name === 'Computer' && (
              <div>
                <br />
                <div className='fw-card-3'>
                    <span className="fw-type-h4">Computer</span>
                  <div style={{borderTop: '1px solid #CFD7DF'}}>
                    <br />
                  </div>
                  <table id="modalComputerDetails">
                    <tbody>
                      <tr>
                        <td className="fw-type-h5">Processor: </td>
                        <td className="fw-type-m">{asset.processor}</td>
                      </tr>
                      <tr>
                        <td className="fw-type-h5">Memory: </td>
                        <td className="fw-type-m">{asset.memory}</td>
                      </tr>
                      <tr>
                        <td className="fw-type-h5">Storage: </td>
                        <td className="fw-type-m">{asset.disk}</td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  </div>
              </div>
              )}

              {asset && (asset.warranty_expiry_date || asset.acquisition_date) && (
                <div>
                  <br />
                  <div className='fw-card-4'>
                    <span className="fw-type-h4">Warranty</span>
                    <div style={{borderTop: '1px solid #CFD7DF'}}></div>
                      <br />
                    <table id="modalWarrantyDetails">
                      <tbody>
                        <tr>
                          <td className="fw-type-h5">Purchase Date: </td>
                          <td className="fw-type-m">{formatDate(asset.acquisition_date)}</td>
                        </tr>
                        <tr>
                          <td className="fw-type-h5">Warranty End Date: </td>
                          <td className="fw-type-m">{formatDate(asset.warranty_expiry_date)}</td>
                        </tr>
                      </tbody>
                    </table>
                    <br />
                  </div>
                </div>
              )}
            </div>         
          </FwModalContent>
          <FwModalFooter>
            <FwButton onClick={() => {
              if (modal.current) {
                  modal.current.close();
                  setSuccessMessage(null); 
              }
            }}>OK</FwButton>
          </FwModalFooter>
        </FwModal>
    </div>
  );
});

export default AssetDetailModal;
import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { FwButton, FwSelect, FwSelectOption, FwInput } from "@freshworks/crayons/react";

export function Filters({ assets, updateFilteredAssets }) {
    console.log('assets:', assets);
    const methodSelect = useRef();

    const filterLabels = {
        user_name: 'User',
        asset_type_name: 'Asset Type',
        location_name: 'Location',
        is_o84_loan_device: 'On Loan from O84?',
        asset_state: 'Asset State'
    };


    // For Each Filter get unique values and initialize state
    const [selectedAssetType, setSelectedAssetType] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState([]);
    const [selectedLoanDevice, setSelectedLoanDevice] = useState([]);
    const [selectedAssetState, setSelectedAssetState] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedUser, setSelectedUser] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    

   
    
    useEffect(() => {
        if (assets.length > 0) {
            console.log(assets[0]);
            setIsLoading(false);
        }
    }, [assets]);

    const getUniqueValues = (assets, property) => {
        return [...new Set(assets.map(asset => asset[property]))];
    };

    const assetTypeOptions = getUniqueValues(assets, 'asset_type_name');
    const locationOptions = getUniqueValues(assets, 'location_name');
    const loanDeviceOptions = getUniqueValues(assets, 'is_o84_loan_device');
    const assetStateOptions = getUniqueValues(assets, 'asset_state');
    const userOptions = getUniqueValues(assets, 'user_name');

    console.log('assetTypeOptions:', assetTypeOptions);
    console.log('locationOptions:', locationOptions);
    console.log('loanDeviceOptions:', loanDeviceOptions);
    console.log('assetStateOptions:', assetStateOptions);
    console.log('userOptions:', userOptions);

    // 
    const onSelectChange = useCallback((e, setSelected) => {
        setSelected(e.detail.value);
      }, []);
    
      
      useEffect(() => {
        if (methodSelect.current) {
            methodSelect.current.setSelectedValues([]);
        }
    }, [methodSelect]);

    const applyFilters = useCallback(() => {
        const filteredAssets = assets.filter(asset =>
            (selectedAssetType.length === 0 || selectedAssetType.includes(asset.asset_type_name)) &&
            (selectedLocation.length === 0 || selectedLocation.includes(asset.location_name)) &&
            (selectedLoanDevice.length === 0 || selectedLoanDevice.includes(asset.is_o84_loan_device)) &&
            (selectedAssetState.length === 0 || selectedAssetState.includes(asset.asset_state)) &&
            (selectedUser.length === 0 || selectedUser.includes(asset.user_name))
        );
        updateFilteredAssets(filteredAssets);
    }, [assets, selectedAssetType, selectedLocation, selectedLoanDevice, selectedAssetState, selectedUser, updateFilteredAssets]);


    // Add function to filter assets based on the search term
    const handleSearch = useCallback(() => {
        const lowerCaseSearchTerm = searchTerm.toLowerCase();
        const filteredAssets = assets.filter(asset =>
            Object.values(asset).some(value =>
                String(value).toLowerCase().includes(lowerCaseSearchTerm)
            )
        );
        if (JSON.stringify(filteredAssets) !== JSON.stringify(assets)) {
            updateFilteredAssets(filteredAssets);
        }
    }, [assets, searchTerm, updateFilteredAssets]);
    
    useEffect(() => {
        handleSearch();
    }, [searchTerm, handleSearch]);

    // Function to clear all filters
    const clearFilters = () => {
        setSelectedAssetType([]);
        setSelectedLocation([]);
        setSelectedLoanDevice([]);
        setSelectedAssetState([]);
        setSelectedUser([]);
        setSearchTerm('');
        handleSearch();
        updateFilteredAssets(assets);
        
};

useEffect(() => {
    const fwButton = document.querySelector('FwButton');
    if (fwButton && fwButton.shadowRoot) {
      const buttonElement = fwButton.shadowRoot.querySelector('button');
      if (buttonElement) {
        buttonElement.style.background = 'black';
        buttonElement.style.color = 'white';
      }
    }
  
    const customButton = document.querySelector('fw-button');
    if (customButton && customButton.shadowRoot) {
      const buttonElement = customButton.shadowRoot.querySelector('button');
      if (buttonElement) {
        buttonElement.style.background = 'black';
        buttonElement.style.color = 'white';
      }
    }
  }, []);



  return (
    <div>
    <div className="SearchHead">
        <br />
        <span className="fw-type-h3">Search</span>
    </div>
    <br />
    <div className="SearchBar">
       <FwInput
            type="text"
            placeholder="Search"
            value={searchTerm}
            onFwInput={(e) => setSearchTerm(e.detail.value)}
            iconLeft="search"
            clearInput={true}
            onFwInputClear={() => {
                setSearchTerm('');
                updateFilteredAssets(assets);
            }}
        />
    </div>
    <br />
    <br />
    <div className="FilterHead">
    <span className="fw-type-h3">Filters</span>  
    </div>
    <br />
    
    <div className="FilterBar">
    {!isLoading && (
        <FwSelect
            key='user'
            className="FwSelect-filters"
            label='User'
            placeholder={`Select an option`}
            multiple
            onFwChange={(e) => onSelectChange(e, setSelectedUser)}
            value={selectedUser}
        >
            {userOptions.map((option, index) => 
                <FwSelectOption key={index} value={option}>{option}</FwSelectOption>
            )}
        </FwSelect>
    )}
        {!isLoading && (
            <FwSelect
                key='asset_type_name'
                className="FwSelect-filters"
                label={filterLabels['asset_type_name']}
                placeholder={`Select an option`}
                multiple
                ref={methodSelect}
                onFwChange={(e) => onSelectChange(e, setSelectedAssetType)}  
                value={selectedAssetType}
            >
                {assetTypeOptions.map((option, index) => (
                    <FwSelectOption key={index} value={option}>
                        {option}
                    </FwSelectOption>
                ))}
            </FwSelect>
        )}
        {!isLoading && (
            <FwSelect
                key='location_name'
                className="FwSelect-filters"
                label={filterLabels['location_name']}
                placeholder={`Select an option`}
                multiple
                onFwChange={(e) => onSelectChange(e, setSelectedLocation)}
                value={selectedLocation}
            >
                {locationOptions.map((option, index) => 
                    <FwSelectOption key={index} value={option}>{option}</FwSelectOption>
                )}
            </FwSelect>
        )}
        {!isLoading && (
            <FwSelect
                key='is_o84_loan_device'
                className="FwSelect-filters"
                label={filterLabels['is_o84_loan_device']}
                placeholder={`Select an option`}
                multiple
                onFwChange={(e) => onSelectChange(e, setSelectedLoanDevice)}
                value={selectedLoanDevice}
            >
                {loanDeviceOptions.map((option, index) => 
                    <FwSelectOption key={index} value={option}>{option}</FwSelectOption>
                )}
            </FwSelect>
        )}
        {!isLoading && (
        <FwSelect
            key='asset_state'
            className="FwSelect-filters-bottom"
            label={filterLabels['asset_state']}
            placeholder={`Select an option`}
            multiple
            onFwChange={(e) => onSelectChange(e, setSelectedAssetState)}
            value={selectedAssetState}
        >
            {assetStateOptions.map((option, index) => 
                <FwSelectOption key={index} value={option}>{option}</FwSelectOption>
            )}
        </FwSelect>
        )}
    </div>
    
    <FwButton onFwClick={applyFilters}>Apply Filters</FwButton>
      <fw-button
        size="icon"
        onClick={clearFilters}
        style={{ marginLeft: '10px'}}
      >
        <fw-icon name="filter-clear"  size="20" color="primary"> </fw-icon>
      </fw-button>
    </div>
  );
}
import React, { useCallback } from 'react';
import { ToastController } from '@freshworks/crayons/react';

const CardHandler = ({originalAssets = [], originalRequesters = [], setAssetData, setRequesterData, filters = []} = {}) => {
    let filteredAssets = [...originalAssets];
    let filteredRequesters = [...originalRequesters];
    
    filters.forEach(filter => {
        switch (filter) {
            case 'computer':
                filteredAssets = originalAssets.filter(asset => asset.asset_type_name === 'Computer' && asset.asset_state !== 'Retired' && asset.asset_state !== 'Archived');
                break;
            case 'all':
                filteredAssets = originalAssets.filter(asset => asset.asset_state !== 'Retired' && asset.asset_state !== 'Archived');
                break;
            case 'available':
                filteredAssets = originalAssets.filter(asset => asset.asset_state === 'In Stock');
                break;
            case 'activeUsers':
                filteredRequesters = originalRequesters.filter(requester => requester.active !== false);
                break;
            default:
                break;
        }
    });
    if (typeof setAssetData === 'function') {
        setAssetData(filteredAssets);
    }
    if (setRequesterData) {
        setRequesterData(filteredRequesters);
    }
    

    const requesterCount = originalRequesters.filter(requester => requester.active !== false).length;
    const computerCount = originalAssets.filter(asset => asset.asset_type_name === 'Computer').length;
    const availableCount = originalAssets.filter(asset => asset.asset_state === 'In Stock').length;
    const allCount = originalAssets.length;

    return { computerCount, availableCount, allCount, filteredAssets, requesterCount };
}

const Card = ({ title, count, disabled, selected, assets, originalAssets, setAssetData, requesters, originalRequesters, setRequesterData, filter, updateTable, setTableType }) => {
    const toast = ToastController({ position: 'top-right' });

    const errorMessages = {
        'computer': 'No computers found',
        'all': 'No assets found',
        'available': 'No available assets found',
        'activeUsers': 'No active users found'
    };

    const handleCardClick = useCallback(() => {
        if (count === 0) {
            toast.trigger({type:'error', content: errorMessages[filter], duration: 3000})
            return;
        }
        let updatedData;
        if (filter === 'activeUsers') {
            updatedData = CardHandler({originalRequesters, setRequesterData, filters: [filter]});
            setTableType('requester');
        } else {
            updatedData = CardHandler({assets, originalAssets, setAssetData, setRequesterData, filters: [filter]});
            setTableType('asset');
        }
        updateTable(updatedData.filteredAssets);
    }, [assets, originalAssets, setAssetData, setRequesterData, filter, updateTable, setTableType, count, originalRequesters, toast]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="card" onClick={handleCardClick} disabled={disabled} selected={selected}>
            <h2>{count}</h2>
            <p>{title}</p>
        </div>
    );
}

export { Card, CardHandler }
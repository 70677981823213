// AssetTable.js
import React from 'react';
import { formatKey, formatDate, formatLink } from './helpers.js';

const keyTextMapping = {
    is_o84_loan_device: 'On Loan from O84?',
    expected_loan_return_date: 'Loan Return Date',
    asset_type_name: 'Asset Type',
    user_name: 'Assigned To',
    location_name: 'Location',
    product_name: 'Product',
    // Add additional mappings if required
};

export const getAssetTableColumns = ({ assets, AssetDetailModalRef }) => {
    const excludedFields = ['user_id', 'id', 'asset_type_id', 'product', 'location_id'];
    const dateFields = ['expected_loan_return_date', 'acquisition_date', 'warranty_expiry_date', 'assigned_on'];

    const columns = Array.from(new Set(assets.reduce((acc, asset) => acc.concat(Object.keys(asset)), [])))
        .filter((key) => !excludedFields.includes(key))
        .map((key) => ({
            key,
            text: keyTextMapping[key] || formatKey(key), 
            ...(key === 'https://help.origin84.com.au' && { formatData: (url) => formatLink(url, AssetDetailModalRef) }),
            ...(dateFields.includes(key) && { formatData: formatDate }),
            style: { textAlign: 'left' }, 
            ...(key === 'name' && { 
                lock: true,
                })
            }) 

        );
        

    return columns;
};

import React, { useRef, useMemo } from 'react';
import { FwDataTable } from '@freshworks/crayons/react';
import { getAssetTableColumns } from './assetTable';
import  AssetDetailModal  from './assetDetailModal';


export const TableConfig = ({ assets }) => {
    const AssetDetailModalRef = useRef();  
    const filteredAssets = assets.filter(asset => asset.asset_state !== 'Retired' && asset.asset_state !== 'Archived');
    const columns = useMemo(() => getAssetTableColumns({ assets: filteredAssets, AssetDetailModalRef }), [filteredAssets, AssetDetailModalRef]);
    

    const rowActions = [
      {
        "name": "View Asset",
        "handler": (asset) => {
          const asset_id = asset.id;
          AssetDetailModalRef.current.open(asset_id);
        },
        "graphicsProps": { name: "browser" }      
      },
      {
        "name": "Update Asset",
        "handler": (asset) => {
          const asset_id = asset.id;
          AssetDetailModalRef.current.open(asset_id);
        },
        "graphicsProps": { name: "edit" }
      }
  ];
    const customStyles = {
        position: 'fixed',
        width: '74vw',
        height: '-webkit-fill-available',
        overflow: 'scroll',
    };

    return (
      <>
        <AssetDetailModal ref={AssetDetailModalRef} assets={assets} />
        <FwDataTable
          key={assets.length}
          columns={columns}
          rows={filteredAssets}
          showSettings={true}
          autoSaveSettings={true}
          style={customStyles}
          rowActions={rowActions}
        />
      </>
    );
};
// Get and Store all Products in localStorage
export const fetchProducts = async (productUrl, config, onUpdate) => {
    const cachedProductData = localStorage.getItem('products');

    // Get Products from the cached data - set it to an empty array if it does not exist
    let cachedProducts = cachedProductData ? JSON.parse(cachedProductData).products : [];

    // Ensure Products is always an array
    let products = Array.isArray(cachedProducts) ? cachedProducts : [];
    let page = 1;

    // Loop through the pages of products until there are no more pages
    while (true) {
        const response = await fetch(`${productUrl}?&page=${page}`, config);

        if (!response.ok) {
            console.error('Error fetching products:', response);
            return;
        }

        const data = await response.json();

        if (Array.isArray(data.products) && data.products.length > 0) {
            // Check for updates and add new records to the products array
            data.products.forEach(newProduct => {
                const existingIndex = products.findIndex(existing => existing.id === newProduct.id);

                if (existingIndex > -1) {
                    // If the product already exists, update the product
                    products[existingIndex] = {
                        ...products[existingIndex],
                        name: newProduct.name,
                    };
                } else {
                    // If the product does not exist, add the product
                    products.push({
                        id: newProduct.id,
                        name: newProduct.name,
                    });
                }
            });

            // Invoke the callback function with the updated products array
            if (onUpdate) {
                onUpdate(products);
            }

            page++;
        } else {
            break;
        }
    }

    // Convert the array of products to an object where the keys are the product_ids and the values are the product names
    const productMap = products.reduce((map, product) => {
        map[product.id] = {
            id: product.id,
            name: product.name,
        };
        return map;
    }, {});

    // Store the products in localStorage cache called 'products'
    localStorage.setItem('products', JSON.stringify({ products: productMap }));

    return productMap;
};
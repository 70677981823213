// AssetTable.js
import React from 'react';
import { formatKey, formatDate, formatLink } from './helpers.js';

const keyTextMapping = {
    name: 'Name',
    email: 'Email Address',
    //number of assets assigned: 'Number of Assets',
    active: 'User Status',
    created_at: 'Creation Date',
    // Add additional mappings if required
};

export const getRequesterTableColumns = ({ requesters }) => {
    const excludedFields = ['id', 'location', 'timezone', 'asset_access', 'ticket_access'];
    const dateFields = ['created_at'];

    const columns = Array.from(new Set(requesters.reduce((acc, requester) => acc.concat(Object.keys(requester)), [])))
        .filter((key) => !excludedFields.includes(key))
        .map((key) => ({
            key,
            text: keyTextMapping[key] || formatKey(key), 
            ...(dateFields.includes(key) && { formatData: formatDate }),
            style: { textAlign: 'left' }, 
            ...(key === 'name' && { 
                lock: true,
                })
            }) 

        );
        

    return columns;
};

import React from 'react';
import { FwDataTable } from '@freshworks/crayons/react';
import { getRequesterTableColumns } from './requesterTable';
/* import  AssetDetailModal  from './assetDetailModal'; */ //to be released in later version


export const RequesterTableConfig = ({ requesters }) => {
    
    //const AssetDetailModalRef = useRef();  
    const filteredRequesters = Array.isArray(requesters) ? requesters.filter(requester => requester.active !== 'false') : [];
    const columns = getRequesterTableColumns({ requesters: filteredRequesters });
    

    //to be used in later version
    /* const rowActions = [
        {
            "name": "View Asset",
            "handler": (asset) => {
                const asset_id = asset.id;
                AssetDetailModalRef.current.open(asset_id);
        },
            "graphicsProps": { name: "browser" }      
        },
        {
            "name": "Update Asset",
            "handler": (asset) => {
                const asset_id = asset.id;
                AssetDetailModalRef.current.open(asset_id);
        },
            "graphicsProps": { name: "edit" }
        }
    ];
 */
    const customStyles = {
        position: 'fixed',
        width: '74vw',
        height: '-webkit-fill-available',
        overflow: 'scroll',
    };
  
    return (
      <>
        <FwDataTable
          key={requesters.length}
          columns={columns}
          rows={filteredRequesters}
          showSettings={true}
          autoSaveSettings={true}
          style={customStyles}
        />
      </>
    );
};
import React, { useState, useEffect, useContext } from 'react';
import { FwForm, FwInput, FwSelect, FwDatepicker, FwButton, FWLabel, FwInlineMessage  } from "@freshworks/crayons/react";
import { clearInputFields } from './helpers.js'; // adjust the path as needed
import '@freshworks/crayons/css/crayons-min.css';
import { useParams } from 'react-router-dom';


const EditAsset = ({ asset }) => {
  const [formFields, setFormFields] = useState({
    assignedTo: '',
    assignedOn: new Date().toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }),
    details: '',
  });

  const [users, setUsers] = useState([]);
  const [successMessage, setSuccessMessage] = useState(null);
  const [ticketId, setTicketId] = useState(null);

  const { requesterId } = useParams();

  const handleInputChange = (event) => {
    setFormFields({
      ...formFields,
      [event.target.name]: event.target.value,
    });
  };


  useEffect(() => {
    const outerRequesters = JSON.parse(localStorage.getItem('requesters'));
    if (outerRequesters && typeof outerRequesters === 'object') {
      const innerRequesters = outerRequesters.requesters;
      if (innerRequesters && typeof innerRequesters === 'object') {
        const users = ['None', ...Object.values(innerRequesters).map(user => user.name)];
        setUsers(users);
        //console.log(users);
        //console.log (users.map(user => ({ 'value': user, 'text': user })));
      }
    }
  }, []);

  useEffect(() => {
    const messageElement = document.querySelector('fw-inline-message');
    if (messageElement) {
      const shadowRoot = messageElement.shadowRoot;
      if (shadowRoot) {
        const iconElement = shadowRoot.querySelector('.alert.alert--success .alert__icon');
        if (iconElement) {
          // Apply styles to iconElement
          iconElement.style.height = 'auto';
        }
      }
    }
  }, [successMessage, ticketId]);

  const handleSubmit = (event) => {
    //console.log('requesterId', requesterId);
    event.preventDefault();
    const { assignedTo, assignedOn, details } = formFields;
    const formattedDescription = `<b>Requested Assignee:</b> ${assignedTo}<br><b>Requested Assignment date:</b> ${new Date(assignedOn).toLocaleDateString()}<br> <b>Asset Tag:</b> ${asset.asset_tag}<br><b>Additional Details:</b> ${details}`;

    const payload = {
      status: 7, // set status as New
      priority: 1, // set priority as Low
      source: 2, // set source as Portal
      subject: 'New Asset Update Request: ' + `${asset.asset_tag}`, // set subject
      description: formattedDescription, // set description
      requester_id: parseInt(requesterId, 10), // convert requesterId to a number

    };

    const key = process.env.REACT_APP_FS_API_KEY;
    const auth = btoa(`${key}` + ':X');

    fetch('https://origin84helpdesk.freshservice.com/api/v2/tickets', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Basic ${auth}`,
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((data) => {
        //console.log('Success:', data);
        setSuccessMessage(`Ticket has been logged Successfully: `);
        setTicketId(data.ticket.id); // Store the ticket ID in the state
        clearInputFields(setFormFields);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };
    
  return (
    <FwForm onSubmit={handleSubmit}>
      <FwSelect id='dynamicSelect' label={'Assigned To'} name="assignedTo" noDataText='Type to search..' value={formFields.assignedTo} onFwChange={handleInputChange} options={users.map(user => ({ 'value': user, 'text': user }))}>
      </FwSelect>
      <FwDatepicker label="Assigned On" name="assignedOn" value={formFields.assignedOn} onChange={handleInputChange} />
      <FwInput type="text" label="Details" name="details" value={formFields.details} onChange={handleInputChange} />
      <br />
      <FwButton type="submit" color="primary" style={{paddingTop: "2px"}} onClick={handleSubmit}>Submit</FwButton>
      <br />
      <br />
      {successMessage && ticketId && 
        <FwInlineMessage open type="success">
          <p>{successMessage} <a href={`https://folk.origin84.com/support/tickets/${ticketId}`} target="_blank" rel="noopener noreferrer">INC-{ticketId}</a></p>
        </FwInlineMessage>
      }
    </FwForm>
  );
};

export default EditAsset;
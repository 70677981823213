
// Format a key into a human-readable string in instances where it hasn't been formally defined in table.js 
export function formatKey(key) {
    return key
        .split('_') // Split the key into words
        .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
        .join(' '); // Join the words back together
}


// Format date into a human-readable string
export function formatDate(dateString) {
    if (!dateString) {
      return '';
    }
  
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  }

// Format a link into a human-readable string
export const formatLink = (assets, AssetDetailModalRef) => {
  return (
    <a 
      href={'https://help.origin84.com'} 
      onClick={(e) => {
        e.preventDefault();
        if (AssetDetailModalRef.current) {
          AssetDetailModalRef.current.open(assets);
        }
      }}
    >
      {'https://help.origin84.com'}
    </a>
  );
};

export const clearInputFields = (setFormFields) => {
  setFormFields({
    assignedTo: '',
    assignedOn: new Date(),
    details: '',
  });
};

import React, { useState, useEffect, useRef } from 'react';
import { fetchAndEnrichAssets } from './fetchAssets.js';   
import { TableConfig } from './tableConfig.js';
import { RequesterTableConfig } from './requesterTableConfig.js';
import { getAssetTableColumns} from './assetTable.js';
import { getRequesterTableColumns } from './requesterTable.js';
import { Filters } from './filters.js';
import AssetDetailModal from './assetDetailModal.js';
import './App.css';
import { Card } from './cards.js';
import { fetchProducts } from './fetchProducts.js';
import { fetchRequesters } from './fetchRequesters.js';
import { fetchLocations } from './fetchLocations.js';
import { FwIcon } from '@freshworks/crayons/react';
import { Routes, Route, Switch } from 'react-router-dom';




const department_id = 76000026640;

const userUrl = `https://origin84helpdesk.freshservice.com/api/v2/requesters`;
const requesterUrl = `https://origin84helpdesk.freshservice.com/api/v2/requesters?query=%22department_id:${department_id}%22&include_agents=true`;
const locationUrl = `https://origin84helpdesk.freshservice.com/api/v2/locations`;
const productUrl = `https://origin84helpdesk.freshservice.com/api/v2/products`;
const assetUrl = `https://origin84helpdesk.freshservice.com/api/v2/assets?include=type_fields&filter="department_id:${department_id}`;

const key = process.env.REACT_APP_FS_API_KEY;
const auth = btoa(`${key}` + ':X');
const config = {
  method: 'GET',
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Basic ${auth}`,
  },
};


function App() {
  const [assets, setAssets] = useState([]);
  const [filteredAssets, setFilteredAssets] = useState([]);
  const [filteredRequesters, setFilteredRequesters] = useState([]);
  const [requesterData, setRequesterData] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [columns, setColumns] = useState([]);
  const [requesters, setRequesters] = useState([]);
  const [locations, setLocations] = useState([]);
  const [products, setProducts] = useState([]);
  const [tableType, setTableType] = useState('asset');
  const [requesterId, setRequesterId] = useState(null);
  const [email, setEmail] = useState(null);

  
    // Function to get user_id from SupportPortal and send asset_access_level back
    useEffect(() => {
        window.addEventListener('message', function(event) {
          if (event.origin !== 'https://folk.origin84.com') return;
      
          //console.log('Received message from SupportPortal:', event.data);
          var user_id = event.data.user_id;
          if (!user_id) return;
      
          // Make the API call to get user info
         // console.log('Making API call to Freshservice')
          fetch(`${userUrl}/${user_id}`, config)
            .then(response => response.json())
            .then(data => {
                // Access the asset_access_level
                //console.log('Received data from Freshservice:', data);
                var asset_access_level = data.requester.custom_fields.asset_access_level;

                setRequesterId(data.requester.id);
                setEmail(data.requester.primary_email);

                // Save requester_id and email to local storage
                localStorage.setItem('CurrentUser', JSON.stringify({
                    requesterId: data.requester.id,
                    email: data.requester.primary_email
                }));
                //console.log('Saved CurrentUser to localStorage:', email, requesterId);

               
      
                // Send the asset_access_level back to the parent
                //console.log('Sending asset_access_level:', asset_access_level);
                event.source.postMessage({ asset_access_level: asset_access_level }, event.origin);
            })
            .catch(error => console.error('Error:', error));
        });
      }, []);


      

    // Function to fetch and set requesters
    useEffect(() => {
        fetchRequesters(requesterUrl, config)
            .then(fetchedRequesters => {
                //console.log('fetchedRequesters:', fetchedRequesters);
                setRequesters(fetchedRequesters);
                setFilteredRequesters(fetchedRequesters);
                setIsDataLoaded(true);

                // Generate the columns using RequesterTable
                setColumns(getRequesterTableColumns({ requesters: fetchedRequesters }));
            });
    }, []);

     // Function to fetch and set locations
     useEffect(() => {
        fetchLocations(locationUrl, config)
            .then(fetchedLocations => {
                setLocations(fetchedLocations);
            });
    }, []);

    useEffect(() => {
        fetchProducts(productUrl, config)
            .then(fetchedProducts => {
                setProducts(fetchedProducts);
            });
    }, []);


   // Fetch and save assets to assets array on page load
   useEffect(() => {
        fetchAndEnrichAssets(assetUrl, config) 
            .then (assets => {
                setAssets(assets);
                setFilteredAssets(assets);
                setIsDataLoaded(true);
            
                // Generate the columns using AssetTable
                setColumns(getAssetTableColumns({ assets: assets }));
            });
    }, []);

    // Function to update filteredAssets
    const updateFilteredAssets = (newFilteredAssets) => {
        //console.log('updateFilteredAssets called with:', newFilteredAssets);
        setFilteredAssets(newFilteredAssets);
    };

    const updateFilteredRequesters = (fetchedRequesters) => {
        //console.log('updateFilteredRequesters called with:', fetchedRequesters);
        setFilteredRequesters(filteredRequesters);
    };
    
    const AssetDetailModalRef = useRef();

    const requesterCount = requesters.filter(requester => requester.active === true).length;
    const computerCount = assets.filter(asset => asset.asset_type_name === 'Computer' && asset.asset_state !== 'Retired' && asset.asset_state !== 'Archived').length;
    const availableCount = assets.filter(asset => asset.asset_state === 'In Stock').length;
    const allCount = assets.filter(asset => asset.asset_state !== 'Retired' && asset.asset_state !== 'Archived').length;
    
    return (
        <div>
        <div className="App-header">
            <div className="App-header-container">
                <div className="App-header-logo">
                    <section className="Pull-left header-section" >
                        <FwIcon name="reply" size={25} color="white" onClick={() => window.location.href='https://folk.origin84.com/support/home'} />
                        <button onClick={() => window.location.href='https://folk.origin84.com/support/home'}>
                            <h4 className="fw-type-h4">Return to Folk Portal</h4>
                        </button>
                    </section>
                </div>
            </div>
        </div>
        <div className="App">
            <Routes>
                <Route path="/:requesterId" element={<></>} />
                {
                    /* 
                    <Route path="*" element={<div>Sorry, You don't have permission to access this page. Please access this page via your portal.</div>} /> 
                    */
                }            
            </Routes>

            <AssetDetailModal ref={AssetDetailModalRef} />
            <div className="App-body">
                <div className="App-body-content">
                    <div className="App-body-cards-main">   
                        <div className="App-body-cards">
                            <Card
                                title="Active Users"
                                count={requesterCount}
                                requesters={requesters}
                                originalUsers={requesters}
                                setRequesterData={setRequesterData}
                                filter="activeUsers"
                                updateTable={updateFilteredRequesters}
                                setTableType={setTableType}
                                onClick={() => {setTableType('requester')}}
                            />
                            <Card 
                                title="All Computers" 
                                count={computerCount} 
                                assets={assets} 
                                originalAssets={assets} 
                                setAssetData={updateFilteredAssets} 
                                filter="computer" 
                                setTableType={setTableType}
                                updateTable={updateFilteredAssets} 
                            />
                            <Card 
                                title="Available Assets" 
                                count={availableCount} 
                                assets={assets} 
                                originalAssets={assets} 
                                setAssetData={updateFilteredAssets} 
                                filter="available" 
                                setTableType={setTableType}
                                updateTable={updateFilteredAssets}
                            />
                            <Card 
                                title="All Assets"
                                count={allCount}
                                assets={assets}
                                originalAssets={assets}
                                setAssetData={updateFilteredAssets}
                                filter="all"
                                setTableType={setTableType}
                                updateTable={updateFilteredAssets}
                            />
                        </div>
                    <div className="App-body-main">
                            {tableType === 'requester' ? <RequesterTableConfig requesters ={filteredRequesters} /> : <TableConfig assets={filteredAssets} AssetDetailModal={AssetDetailModalRef} /> }
                    </div>
                </div>
                <div className="App-body-sidebar">
                    <div className="App-sidebar-filters">
                        {isDataLoaded && tableType !== 'requester' && <Filters assets={assets} updateFilteredAssets={updateFilteredAssets} />}
                    </div>
                </div>
                </div>      
            </div>
        </div>
    </div>
        
    );
}
    
    export default App;
    
